import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import Layout from '../components/layout';

const AboutWebsite = ({ data }: PageProps<DataType>): JSX.Element => {
  const { allOssLicense } = data;
  const { nodes } = allOssLicense;
  return (
    <Layout seo={{ title: '本ブログについて' }}>
      <section className="section">
        <div className="container">
          <h1 className="title is-spaced">本ブログについて</h1>
          <h2 className="subtitle">オープンソース ライセンス</h2>
          <p className="block">本ブログは下記のオープンソースを利用して作成しています。</p>
          <ul className="block">
            {nodes.map((node) => (
              <li className="is-size-6"><Link className="has-text-dark" to={`/oss-license/${node.name}`}>{node.name}</Link></li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default AboutWebsite;

export const pageQuery = graphql`
  query {
    allOssLicense {
      nodes {
        id
        name
      }
    }
  }
`;

type DataType = {
  allOssLicense: {
    nodes: {
      id: string;
      name: string;
    }[];
  };
};
